.positive {

    font-family: Roboto, sans-serif;
    font-weight: 10;
    font-size: 11px;
    color: #fff;
    background-color: #2db013;
    padding: 5px 5px;
    border: solid #01820c 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition: 1000ms;
    transform: translateY(0);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    width: 200px;
}

.negative {

    font-family: Roboto, sans-serif;
    font-weight: 10;
    font-size: 11px;
    color: #fff;
    background-color: #e3394d;
    padding: 5px 5px;
    border: solid #910404 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition: 1000ms;
    transform: translateY(0);
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    justify-content: center;
    width: 200px;
}

.info {

    font-family: Roboto, sans-serif;
    font-weight: 10;
    font-size: 11px;
    color: #fff;
    background-color: #0066CC;
    padding: 5px 5px;
    border: 2px solid #0066cc;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition: 1000ms;
    transform: translateY(0);
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    justify-content: center;
    width: 200px;
}

.flex-container {
    display: flex;
    height: 80%;
    padding: 5px;
    gap: 5px;
}

.bdd {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    letter-spacing: 0.2px;
    word-spacing: 0.6px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: uppercase;
}


@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');
@import url('https://fonts.googleapis.com/css?family=Cambay');
@import url('https://fonts.googleapis.com/css?family=Laila');

.bddtext {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
    font-variant: small-caps;
    text-align: center;
    font-size: 15px;
    letter-spacing: 1px;
    word-spacing: -3px;
    color: rgb(0, 0, 0);
}

.cardheight {
    height: 200px;
}