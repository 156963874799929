.tooltip-inner {
  white-space: pre-line;
}
.functional-pending-test-status {
  color: #007dbc !important;
}
.functional-passed-test-status {
  color: #1e8900 !important;
}
.functional-failed-test-status {
  color: #df3312 !important;
}
.functional-error-test-status {
  color: #df3312 !important;
}
.functional-running-test-status {
  color: #007dbc !important;
}
.functional-not-applicable-test-status {
  color: #879596 !important;
}
.ellipsis-anim-with-color span {
  opacity: 0;
  color: #007dbc !important;
  white-space: nowrap;
  -webkit-animation: ellipsis-dot 1s infinite;
  animation: ellipsis-dot 1s infinite;
}
.overall-validation-reason-style {
  font-size: x-small;
  white-space: nowrap;
  word-wrap: break-word !important;
  color: #8B4513 !important;
}
.nowrap-style {
  white-space: nowrap;
}
.utterances-button-style {
  color: #007dbc !important;
  text-decoration: underline;
}
.wrap-text-style {
  min-width: 300px !important;
  max-width: 300px !important;
  word-wrap:break-word !important;
}
.utterance-comments-style {
  font-size: x-small;
  word-wrap: break-word !important;
  color: #8B4513 !important;
}
.utterance-comments-failed-status-style {
  font-size: x-small;
  word-wrap: break-word !important;
  color: #df3312 !important;
}
.test-category-text-style {
  color: #4c4c4c !important;
}
.ref-mic-status-style {
  color: #696969 !important;
}
.ref-mic-online-style {
  color: #1e8900 !important;
}
.ref-mic-offline-style {
  color: #df3312 !important;
}
.ref-mic-unavailable-style {
  color: #007dbc !important;
}
.ref-mic-recording-dot-style {
  color: #df3312 !important;
  font-size: 400%;
}
.ref-mic-recording-text-style {
  color: #007dbc !important;
}
.ref-mic-recording-braces-style {
  color: #696969 !important;
}