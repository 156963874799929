.filler {
  background: #1e8900;
  height: 100%;
  transition: width .2s ease-in;
}
.progress-bar {
  position: relative;
  height: 8px;
  width: 100%;
  border: 0px;
  border-radius: 0px;
}
.breadcrumb-style {
  display: table;
  margin: 0 auto;
  padding: inherit;
}